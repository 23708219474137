import React, { Suspense } from 'react';
// import logo from './logo.svg';
// import './App.scss';
// import  LandingPage  from "./Pages/landing.page";

// import { AppLayout } from "./Pages/app.layout";
// import { ProtectedRoute } from "./protected.route";
import {  Switch, Route, HashRouter } from "react-router-dom";


// import  Signin  from "./Pages/Signin/Signin.page";
// import  Grid  from "./Pages/flexbox/grid";
// import  Sidebar  from "./Pages/sidebar/sidebar";
// import  Cv  from "./Pages/portfolio/cv";
// import Homepage from './Pages/portfolio/homePage';


// const LandingPage = React.lazy(() => import("./Pages/landing.page"));
const Homepage = React.lazy(() => import("./Pages/portfolio/homePage"));
const Cv = React.lazy(() => import("./Pages/portfolio/cv"));
const Sidebar = React.lazy(() => import("./Pages/sidebar/sidebar"));
const Grid = React.lazy(() => import("./Pages/flexbox/grid"));
const Signin = React.lazy(() => import("./Pages/Signin/Signin.page"));





function App() {
  return (
    <div className="App">
      {/* <h1>Protected Route</h1> */}
      <HashRouter>
      <Switch>
      {/* <Route exact path="/" component={LandingPage} /> */}

      <Route exact path="/signin" component={Signin} 
      
      
      />
      <Route exact path="/" component={ ()=><Suspense fallback={<div>Loading....</div>}>
            <Homepage/>
      </Suspense> } />
      <Route exact path="/grid" component={Grid} />
      <Route exact path="/sidebar" component={Sidebar} />

      <Route exact path="/cv" component={Cv} />
      {/* <ProtectedRoute exact path="/app" component={AppLayout} /> */}
      <Route exact path="*" component={ () => "404 not found"} />
      </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
