const initState = {
    posts: []
}

const rootReducer = (state = initState, action) => {
    if (action.type === 'post') {
        return {
            ...state,
            posts: 'hellow'
        }
    }

    return state;
}

export default rootReducer;